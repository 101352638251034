import React from "react";
import { useRecoilState } from "recoil";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useAuth } from "../hooks/useAuth";
import { verifyDocument } from "../api/Documents";

import documentVerificationStatusState from "../recoil/documentVerificationStatusState";

export default function DocumentStatusSelect(props) {
  const { documentId } = props;
  const [documentVerificationStatus, setDocumentStatus] = useRecoilState(
    documentVerificationStatusState
  );
  const auth = useAuth();

  const handleStatusChange = (event) => {
    let status = event.target.value;
    let isVerified = status === "verified";
    setDocumentStatus(status);

    auth.getAccessJwtToken().then((token) => {
      verifyDocument(token, documentId, isVerified).then((response) => {});
    });
  };

  return (
    <Box sx={{ pt: 1 }}>
      <Typography
        color="secondary"
        sx={{ fontSize: 16, display: "inline", pr: 2 }}>
        Status
      </Typography>
      <Select
        value={documentVerificationStatus}
        onChange={handleStatusChange}
        sx={{
          "minWidth": 150,
          "color": "white",
          "& .MuiSelect-select": { py: 1 },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "secondary.main",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "secondary.main",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "secondary.main",
            borderWidth: 1,
          },
          "& .MuiSelect-icon": { color: "white" },
        }}>
        <MenuItem value={"unverified"}>Needs review</MenuItem>
        <MenuItem value={"verified"}>Verified</MenuItem>
      </Select>
    </Box>
  );
}
