import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const LoadingMessage = ({ text = "Loading..." }) => (
  <Box
    sx={{
      textAlign: "left",
      height: 40,
      mb: "50px",
      display: "flex",
      alignItems: "center",
    }}>
    <Typography variant="h5" sx={{ opacity: 0.4, mr: 2 }}>
      {text}
    </Typography>
    <CircularProgress size={32} color="secondary" />
  </Box>
);

export default React.memo(LoadingMessage);
