import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import _ from "lodash";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

import { deleteDocument } from "../api/Documents";
import { useAuth } from "../hooks/useAuth";

import documentIdsState from "../recoil/documentIdsState";

export default function DeleteDocumentButton(props) {
  const [isDeleteWorkflowUnderway, setIsDeleteWorkflowUnderway] =
    useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [documentIds, setDocumentIds] = useRecoilState(documentIdsState);

  const { documentId } = props;

  const navigate = useNavigate();
  const auth = useAuth();

  const handleTrashButtonClick = () => {
    setIsDeleteWorkflowUnderway(true);
  };

  const handleCancelClick = () => {
    setIsDeleteWorkflowUnderway(false);
  };

  const handleDeleteDocument = () => {
    setIsDeleteInProgress(true);

    ReactGA.event({
      category: "Document",
      action: "Delete",
    });

    auth.getAccessJwtToken().then((token) => {
      deleteDocument(token, documentId).then(() => {
        let newDocumentIds = _.filter(
          documentIds,
          (docId) => docId !== documentId
        );
        setDocumentIds(newDocumentIds);
        navigate("/documents");
      });
    });
  };

  return isDeleteWorkflowUnderway ? (
    <Box sx={{ pt: 1.75, px: 2 }}>
      <Button
        onClick={handleCancelClick}
        variant="outlined"
        color="secondary"
        disabled={isDeleteInProgress}>
        Cancel
      </Button>
      <Button
        onClick={handleDeleteDocument}
        variant="contained"
        disableElevation
        color="error"
        disabled={isDeleteInProgress}
        sx={{ ml: 2 }}>
        {isDeleteInProgress ? "Deleting..." : "Confirm delete"}
      </Button>
    </Box>
  ) : (
    <Box sx={{ pt: 1.5, px: 2 }}>
      <Tooltip title="Delete this document">
        <IconButton
          onClick={handleTrashButtonClick}
          aria-label="Delete this document"
          size="medium"
          color="secondary"
          sx={{ fontSize: 24 }}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
