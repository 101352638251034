import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { useInView } from "react-intersection-observer";
import _ from "lodash";

import Box from "@mui/material/Box";

import TextOverlay from "./overlays/TextOverlay";
import TableOverlay from "./overlays/TableOverlay";
import HighlightedBlock from "./overlays/HighlightedBlock";
import EnvironmentalObservationOverlay from "./overlays/EnvironmentalObservationOverlay";
import useBoundingBoxes from "../hooks/useBoundingBoxes";
import BlankDocumentImage from "../images/blankDocument.png";
import { useAuth } from "../hooks/useAuth";
import {
  getPageImage,
  getPageTables,
  getPageWords,
  getPageEnvironmentalObservations,
} from "../api/Documents";

import documentLayersState from "../recoil/documentLayersState";
import tableOfContentsPageSelector from "../recoil/tableOfContentsPageSelector";

export default function DocumentPage(props) {
  const { documentId, pageNumber, highlightedBlock, searchResults } = props;

  const [imageUrl, setImageUrl] = useState(null);
  const [tables, setTables] = useState([]);
  const [words, setWords] = useState([]);
  const [environmentalObservations, setEnvironmentalObservations] = useState(
    []
  );
  const [tooltipFields, setTooltipFields] = useState([]);
  const documentLayers = useRecoilValue(documentLayersState);
  const tableOfContentsEntries = useRecoilValue(
    tableOfContentsPageSelector(pageNumber)
  );

  const auth = useAuth();
  const [pageRef, isPageInView] = useInView({
    triggerOnce: true,
    rootMargin: "3000px 0px",
    trackVisibility: true,
    delay: 500,
  });

  const isHandwritingVisible = documentLayers.handwritingOverlay;
  const areEnvironmentalObservationsVisible =
    documentLayers.environmentalMonitoringObservations;
  const areTableAnnotationsVisible =
    !documentLayers.environmentalMonitoringObservations;

  const handwritingAnnotations = _.filter(
    words,
    (block) => block["TextType"] === "HANDWRITING"
  );

  const getPercentageBasedBoundingBox = useBoundingBoxes();

  React.useEffect(() => {
    if (isPageInView) {
      auth.getAccessJwtToken().then((token) => {
        getPageImage(token, documentId, pageNumber).then((image) => {
          setImageUrl(image.Url);
        });

        getPageTables(token, documentId, pageNumber).then((tables) => {
          setTables(tables);
        });

        getPageWords(token, documentId, pageNumber).then((words) => {
          setWords(words);
        });

        getPageEnvironmentalObservations(token, documentId, pageNumber).then(
          (environmentalObservations) => {
            setEnvironmentalObservations(environmentalObservations.Data);
            setTooltipFields(environmentalObservations.TooltipFields);
          }
        );
      });
    }
  }, [auth, documentId, pageNumber, isPageInView]);

  const updateObservation = (newObservation) => {
    let newObservations = _.clone(environmentalObservations);
    _.remove(
      newObservations,
      (obs) => obs.CellBlockId === newObservation.CellBlockId
    );
    newObservations.push(newObservation);
    setEnvironmentalObservations(newObservations);
  };

  return (
    <Box ref={pageRef} sx={{ minHeight: "1000px" }}>
      <Box sx={{ position: "relative" }}>
        <img
          src={imageUrl ? imageUrl : BlankDocumentImage}
          alt={"Page " + pageNumber}
          width="100%"
        />

        {searchResults.map((searchResult) => {
          const boundingBox = getPercentageBasedBoundingBox(searchResult);

          return (
            <Box
              sx={{
                position: "absolute",
                top: boundingBox.top,
                left: boundingBox.left,
                right: boundingBox.right,
                bottom: boundingBox.bottom,
                scrollMarginTop: 175,
                backgroundColor: "rgba(255, 233, 101, 0.55)",
                border: "1px dashed lightgray",
              }}
              ref={searchResult.scrollRef}
              key={`find-highlight-${searchResult.Id}`}></Box>
          );
        })}

        {isHandwritingVisible &&
          handwritingAnnotations.map((block) => {
            const boundingBox = getPercentageBasedBoundingBox(block);

            return (
              <TextOverlay
                text={block.Text}
                boundingBox={boundingBox}
                key={`handwriting-block-${block.Id}`}
              />
            );
          })}

        {areTableAnnotationsVisible &&
          tables.map((tableBlock) => {
            return (
              <TableOverlay
                documentId={documentId}
                tableBlock={tableBlock}
                key={tableBlock.Id}
              />
            );
          })}

        {highlightedBlock && <HighlightedBlock block={highlightedBlock} />}

        {tableOfContentsEntries.map((entry) => {
          let linkedBlock = entry.LinkedBlock;
          const boundingBox = getPercentageBasedBoundingBox(linkedBlock);

          return (
            <Box
              sx={{
                position: "absolute",
                top: boundingBox.top,
                left: boundingBox.left,
                right: boundingBox.right,
                bottom: boundingBox.bottom,
                scrollMarginTop: 135,
              }}
              ref={linkedBlock.scrollRef}
              key={`table-of-contents-destination-${linkedBlock.Id}`}></Box>
          );
        })}

        {areEnvironmentalObservationsVisible &&
          environmentalObservations.map((observation) => {
            return (
              <EnvironmentalObservationOverlay
                observation={observation}
                documentId={documentId}
                tooltipFields={tooltipFields}
                updateObservation={updateObservation}
                key={observation.CellBlockId}
              />
            );
          })}
      </Box>
    </Box>
  );
}
