import React from "react";
import { useRecoilState } from "recoil";
import _ from "lodash";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import documentLayersState from "../recoil/documentLayersState";

export default function DocumentModeSelect(props) {
  const [layers, setLayers] = useRecoilState(documentLayersState);

  const setViewMode = (event) => {
    let newMode = event.target.value;
    let newLayers = _.clone(layers);
    newLayers.environmentalMonitoringObservations = newMode === "review";
    setLayers(newLayers);
  };

  return (
    <Box>
      <Select
        value={
          layers.environmentalMonitoringObservations ? "review" : "reading"
        }
        onChange={setViewMode}
        sx={{
          "minWidth": 150,
          "color": "white",
          "& .MuiSelect-select": { py: 1 },
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "secondary.main",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "secondary.main",
            borderWidth: 1,
          },
          "& .MuiSelect-icon": { color: "white" },
        }}>
        <MenuItem value={"reading"}>Reading mode</MenuItem>
        <MenuItem value={"review"}>Review mode</MenuItem>
      </Select>
    </Box>
  );
}
