import { ScatterChart } from "@carbon/charts-react";
import Box from "@mui/material/Box";

import React from "react";

import * as Helpers from "./EnvironmentalMonitorChart.helpers";

import "@carbon/charts/styles.css";
import "./reports.css";

export default function EnvironmentalMonitoringChart({
  title,
  roomName,
  observations,
  chartConfiguration,
  thresholds,
  isLoading,
  width,
  height,
  xMin,
  xMax,
}) {
  const chartRef = React.useRef(null);

  const chartData = observations.map((observation) => ({
    ...observation,
    group: observation.SeriesLabel,
  }));

  const chartOptions = Helpers.useChartOptions({
    title,
    roomName,
    chartConfiguration,
    thresholds,
    isLoading,
    height,
    xMin,
    xMax,
  });

  Helpers.useLayerSwitching(chartRef);
  Helpers.useCustomChartEvents(chartRef);

  return (
    <Box sx={{ pb: 5 }}>
      <ScatterChart ref={chartRef} data={chartData} options={chartOptions} />
    </Box>
  );
}
