import React, { useEffect, useLayoutEffect } from "react";
import { RecoilRoot } from "recoil";
import { Route, Routes, Outlet, Navigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import NavBar from "./NavBar";
import BatchRecords from "./documentsListView/BatchRecords";
import Document from "./documentView/Document";
import LogIn from "./LogIn";
import { useAuth } from "./hooks/useAuth";
import useAnalyticsTracking from "./hooks/useAnalyticsTracking";
import Reports from "./reports/Reports";
import PrintReports from "./reports/PrintReports";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5f7afe",
    },
    secondary: {
      main: "#98a1d8",
    },
    appBarIcon: {
      main: "#ffffff",
    },
    fathomGray: {
      main: "#555d71",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
    fontSize: 14,
    htmlFontSize: 14,
    button: {
      textTransform: "none",
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
    code: {
      fontFamily: "Courier New, source-code-pro, monospace",
    },
  },
});

const ScrollWrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const PrintWrapper = () => (
  <main>
    <Outlet />
  </main>
);

const PageWrapper = ({ children }) => (
  <div className="App">
    <NavBar />
    <Box>
      <ScrollWrapper>{children}</ScrollWrapper>
    </Box>
  </div>
);

function App() {
  const auth = useAuth();

  useEffect(() => {
    const googleAnalyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
    if (googleAnalyticsKey) {
      ReactGA.initialize(googleAnalyticsKey, {
        gaOptions: {
          siteSpeedSampleRate: 100,
        },
      });
    }
  }, []);

  useEffect(() => {
    ReactGA.set({ userId: auth.user ? auth.user.attributes.sub : "" });
  }, [auth]);

  useAnalyticsTracking();

  let routes = (
    <Routes>
      <Route path="/print" element={<PrintWrapper />}>
        <Route path="reports" element={<PrintReports />} />
      </Route>
      <Route
        path="/"
        element={
          <PageWrapper>
            <Outlet />
          </PageWrapper>
        }>
        <Route index element={<Navigate replace to="/documents" />} />
        <Route path="documents" element={<BatchRecords />} />
        <Route path="documents/:documentId" element={<Document />} />
        <Route path="reports" element={<Reports />} />
        <Route path="*" element={<Navigate replace to="/documents" />} />
      </Route>
    </Routes>
  );

  if (!auth.user) {
    routes = (
      <Routes>
        <Route
          path="*"
          element={
            <PageWrapper>
              <LogIn />
            </PageWrapper>
          }
        />
      </Routes>
    );
  }

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>{routes}</ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
