import styled from "@emotion/styled";

import { Link } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import WarningIcon from "@mui/icons-material/Warning";

import React from "react";
import { DateTime } from "luxon";

const TooltipStyles = styled.div`
  .em-observation-tooltip {
    min-width: 240px;
    padding: 6px 8px 4px 8px;
    color: #555d71;

    .section {
      padding-bottom: 3px;
      border-bottom: 1px solid #eee;
      margin-bottom: 3px;
    }

    .section:last-of-type {
      padding-bottom: initial;
      border-bottom: initial;
      margin-bottom: initial;
    }

    .section.header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .room-name {
        font-weight: 600;
        font-size: 12px;
      }
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 2px 0;

      .field {
        font-size: 11px;
        color: #999;
        text-overflow: ellipsis;
      }

      .value {
        font-size: 11px;
        color: #121212;
        text-overflow: ellipsis;
      }
    }

    .link {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: blue;

      .icon {
        margin-right: 4px;
      }
    }

    .section.footer {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 4px;

      .verification-details {
        font-style: italic;
        font-size: 10px;
        color: #aaa;
      }
    }
  }
`;

const DefaultTZ = {
  zone: "utc",
};

function formatDateValue(value, format) {
  const datetime = DateTime.fromFormat(value, format, DefaultTZ);
  return datetime.isValid ? datetime.toLocaleString(DateTime.DATE_MED) : value;
}

const TooltipField = ({ field, previous = undefined }) => {
  const currentTitle = field.title;
  const previousTitle = previous?.title;

  const title = currentTitle !== previousTitle ? currentTitle : "";

  return <div className="field">{title}</div>;
};

const TooltipValue = ({ item, field, disableLinks = false }) => {
  const fieldValue = item[field.key];
  const fieldType = field.type === "URL" && disableLinks ? "Text" : field.type;

  switch (fieldType) {
    case "Date":
      return (
        <div className="value">{formatDateValue(fieldValue, field.format)}</div>
      );
    case "URL":
      return (
        <div className="value">
          <Link href={item[field.linkKey]} target="_blank">
            <div className="link">
              <div className="icon">
                <LinkIcon
                  sx={{ width: 12, height: 12, margin: 0, padding: 0 }}
                />
              </div>
              <div className="content">{fieldValue}</div>
            </div>
          </Link>
        </div>
      );
    // TODO add support for more field types here...
    default:
      return <div className="value">{fieldValue}</div>;
  }
};

const TooltipHeader = ({ item }) => {
  if (item.roomName === undefined) return null; // skip rendering empty header

  const warningColor = item.warningColor ?? "transparent";

  return (
    <div className="section header">
      <div className="room-name">{item.roomName}</div>
      <div className="warning-level">
        <WarningIcon
          sx={{
            width: 14,
            height: 14,
            margin: 0,
            padding: 0,
            color: warningColor,
          }}
        />
      </div>
    </div>
  );
};

const TooltipSection = ({ name, fields, item, disableLinks = false }) => {
  const sectionFields = fields.filter((field) => field.section === name);

  if (sectionFields.length < 1) return null; // skip rendering empty sections

  return (
    <div className="section">
      {sectionFields.map((field, index) => (
        <div key={field.key} className="item">
          <TooltipField field={field} previous={sectionFields[index - 1]} />
          <TooltipValue item={item} field={field} disableLinks={disableLinks} />
        </div>
      ))}
    </div>
  );
};

const TooltipFooter = ({ item }) => {
  if (item.verificationDetails === undefined) return null; // skip rendering empty footer

  return (
    <div className="section footer">
      <div className="verification-details">
        verified {item.verificationDetails}
      </div>
    </div>
  );
};

const ObservationTooltip = ({ fields, item, disableLinks = false }) => {
  const allSections = fields.map((f) => f.section);
  const distinctSections = new Set(allSections);
  const sectionNames = Array.from(distinctSections);

  // only show tooltip fields for which we have a value available
  const fieldsWithValues = fields.filter(
    (field) => item[field.key] !== undefined
  );

  return (
    <TooltipStyles>
      <div className="em-observation-tooltip">
        <TooltipHeader item={item} />
        {sectionNames.map((sectionName) => (
          <TooltipSection
            key={sectionName}
            name={sectionName}
            fields={fieldsWithValues}
            item={item}
            disableLinks={disableLinks}
          />
        ))}
        <TooltipFooter item={item} />
      </div>
    </TooltipStyles>
  );
};

export default React.memo(ObservationTooltip);
