import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";

import { useAuth } from "./hooks/useAuth";
import Logo from "./images/logo.png";

import getUser from "./api/getUser";

import currentUserState from "./recoil/currentUserState";
import isSuperuserState from "./recoil/isSuperuserState";

export default function NavBar() {
  const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

  const navigate = useNavigate();

  const auth = useAuth();
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const isSuperuser = useRecoilValue(isSuperuserState);

  useEffect(() => {
    if (auth.user) {
      auth.getAccessJwtToken().then((token) => {
        getUser(token).then((user) => {
          setCurrentUser(user);
        });
      });
    } else {
      setCurrentUser(null);
    }
  }, [auth, setCurrentUser]);

  const downgradeSuperuser = () => {
    let user = _.clone(currentUser);
    user.IsSuperuser = false;
    setCurrentUser(user);
  };

  const getLogoImage = () => {
    let logo = (
      <img src={Logo} alt="Fathom" style={{ maxHeight: 30, marginLeft: -12 }} />
    );

    if (isSuperuser) {
      logo = (
        <Box
          sx={{ cursor: "pointer", maxHeight: 30 }}
          onClick={downgradeSuperuser}>
          <Tooltip title="You are a superuser">{logo}</Tooltip>
        </Box>
      );
    }

    return logo;
  };

  return (
    <React.Fragment>
      <AppBar
        position="static"
        style={{
          backgroundColor: "#7d87c4",
          padding: "0 10px",
          boxShadow: "none",
          position: "fixed",
          zIndex: 100,
        }}>
        <Toolbar>
          {getLogoImage()}

          {auth.user && (
            <React.Fragment>
              <Button
                sx={{ marginLeft: "auto", color: "white", px: 5 }}
                onClick={() => navigate("/documents")}>
                Documents
              </Button>

              <Button
                sx={{ color: "white", px: 5 }}
                onClick={() => navigate("/reports")}>
                Reports
              </Button>

              <Button sx={{ color: "white", pl: 5 }} onClick={auth.logout}>
                Log out
              </Button>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Offset />
    </React.Fragment>
  );
}
