import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// import Typography from "@mui/material/Typography";

import DocumentList from "./DocumentList";
import UploadDialog from "./UploadDialog";
import useDebounce from "../hooks/useDebounce";

// regarding padding definitions on top-level Box element

// notes on shorthand syntax in SX:
// https://mui.com/system/basics/#the-sx-prop

// notes on spacing measurements in SX:
// https://mui.com/customization/spacing/

// info on using breakpoints:
// https://mui.com/customization/breakpoints/

export default function BatchRecords() {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [filterText, setFilterText] = useState("");

  const debouncedFilterText = useDebounce(filterText, 500);

  const updateFilter = React.useCallback((event) => {
    const value = event.target.value;
    const updatedValue = value.length === 0 ? undefined : value;

    setFilterText(updatedValue);
  }, []);

  return (
    <Box sx={{ px: { xs: 1, md: 3, lg: "10%" }, pt: 7 }}>
      <Box
        sx={{
          display: "flex",
          mb: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        {/* <Typography variant={"h4"} color="fathomGray.main">
          Documents
        </Typography> */}
        <Box sx={{ flexGrow: 1, display: "flex", px: 1 }}>
          <TextField
            label="Filter documents by name"
            type="search"
            variant="standard"
            value={filterText}
            onChange={updateFilter}
            sx={{ width: 300 }}
          />
        </Box>

        <Box sx={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<AddCircleIcon />}
            onClick={() => setIsUploadDialogOpen(true)}>
            Add new
          </Button>
        </Box>
      </Box>

      <DocumentList filterText={debouncedFilterText} />

      {isUploadDialogOpen && (
        <UploadDialog
          open={isUploadDialogOpen}
          onClose={() => setIsUploadDialogOpen(false)}
        />
      )}
    </Box>
  );
}
