import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

const ChartPlaceholders = ({ count = 4 }) => (
  <Grid container columnSpacing={15} rowSpacing={7}>
    {Array.from({ length: count }).map((_, index) => (
      <Grid item xs={12} md={6} key={`observation-chart-${index}`}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContents: "space-between",
            gap: "8px",
            pt: "8px",
          }}>
          <Box sx={{ width: "240px", height: "20px" }}>
            <Skeleton width="100%" animation="wave">
              <div style={{ height: 24 }} />
            </Skeleton>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ width: "20px", height: "20px" }}>
            <Skeleton width={20} height={20} animation={false}>
              <div />
            </Skeleton>
          </Box>
          <Box sx={{ width: "20px", height: "20px" }}>
            <Skeleton width={20} height={20} animation={false}>
              <div />
            </Skeleton>
          </Box>
        </Box>
        <Box sx={{ pt: "26px" }}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={412}
            animation="wave">
            <div />
          </Skeleton>
        </Box>
        <Box sx={{ pt: "20px" }}>
          <Skeleton
            variant="rectangular"
            width={160}
            height={16}
            animation={false}>
            <div />
          </Skeleton>
        </Box>
      </Grid>
    ))}
  </Grid>
);

export default React.memo(ChartPlaceholders);
