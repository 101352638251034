export default function useBoundingBoxes() {
  function getPercentageBasedBoundingBox(block) {
    let verticalHighlightPadding = 0.003;
    let horizontalHighlightPadding = 0.005;

    let top = block.Geometry.BoundingBox.Top - verticalHighlightPadding;
    let left = block.Geometry.BoundingBox.Left - horizontalHighlightPadding;
    let bottom =
      1 -
      (block.Geometry.BoundingBox.Top + block.Geometry.BoundingBox.Height) -
      verticalHighlightPadding;
    let right =
      1 -
      (block.Geometry.BoundingBox.Left + block.Geometry.BoundingBox.Width) -
      horizontalHighlightPadding;

    return {
      top: `${top * 100}%`,
      left: `${left * 100}%`,
      bottom: `${bottom * 100}%`,
      right: `${right * 100}%`,
    };
  }

  return getPercentageBasedBoundingBox;
}
