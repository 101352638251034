import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useRecoilCallback } from "recoil";
import ReactGA from "react-ga4";
import axios from "axios";
import _ from "lodash";
import { DateTime } from "luxon";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { useAuth } from "../hooks/useAuth";
import updateDocumentStatus from "./updateDocumentStatus";
import StyledDropzoneArea from "../DropzoneArea";

import documentIdsState from "../recoil/documentIdsState";
import documentsStateFamily from "../recoil/documentsStateFamily";
import currentUserState from "../recoil/currentUserState";

export default function UploadDialog(props) {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const currentUser = useRecoilValue(currentUserState);
  const [documentIds, setDocumentIds] = useRecoilState(documentIdsState);
  const setDocument = useRecoilCallback(({ set }) => (document) => {
    set(documentsStateFamily(document.DocumentId), document);
  });

  const { onClose } = props;

  const auth = useAuth();

  useEffect(() => {
    ReactGA.event({ category: "Upload document", action: "View upload modal" });
  }, []);

  const handleUpload = (files) => {
    setIsUploadInProgress(true);

    ReactGA.event({ category: "Upload document", action: "Upload document" });

    auth.getAccessJwtToken().then((token) => {
      let newDocuments = [];
      let fileUploadPromises = files.map((file) => {
        return axios({
          method: "get",
          url: `${process.env.REACT_APP_SERVER_URL}/upload?filename=${file.name}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((uploadResponse) => {
          let uploadUrl = uploadResponse.data.url;
          let documentData = uploadResponse.data.fields;
          let formData = new FormData();
          _.forEach(documentData, (value, key) => {
            formData.append(key, value);
          });
          formData.append("file", file);

          newDocuments.push({
            DocumentId: documentData["x-amz-meta-document-id"],
            Filename: documentData["x-amz-meta-filename"],
            ContentType: documentData["x-amz-meta-content-type"],
            IsValidated: false,
            ReceivedTime: DateTime.now().toUTC().toISO(),
            UploadedByUser: {
              UserId: documentData["x-amz-meta-uploaded-by-user-id"],
              EmailAddress: currentUser.EmailAddress,
            },
            IsInitialProcessingComplete: false,
            Status: "Processing",
          });

          return axios({
            method: "post",
            url: uploadUrl,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).catch((error) => {
            alert(`Failed to upload ${file.name}.`);
            console.log(error);
            setIsUploadInProgress(false);
            return Promise.reject(error);
          });
        });
      });

      Promise.all(fileUploadPromises).then(() => {
        onClose();
        setIsUploadInProgress(false);

        _.forEach(newDocuments, (newDocument) => {
          setDocument(newDocument);
          updateDocumentStatus(token, newDocument.DocumentId, setDocument);
        });

        let newDocumentIds = _.union(
          documentIds,
          newDocuments.map((document) => document.DocumentId)
        );
        setDocumentIds(newDocumentIds);
      });
    });
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <Box sx={{ my: 3, mx: 5 }}>
        <DialogTitle>
          <Typography sx={{ mb: 1, fontSize: "2rem" }}>
            Upload documents
          </Typography>
          <Typography sx={{ fontSize: "1rem" }} color="fathomGray.main">
            You can select multiple files to upload at a time. Files should be
            .pdf.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <StyledDropzoneArea
            Icon={CloudUploadOutlinedIcon}
            dropzoneText={"Drop files to attach or click to browse."}
            acceptedFiles={["application/pdf"]}
            onChange={(files) => setFilesToUpload(files)}
            showFileNames
            filesLimit={10}
            maxFileSize={1024000000}
            showAlerts={false}
            onDrop={(files) => setFilesToUpload(files)}
            showPreviews={true}
            showPreviewsInDropzone={false}
            useChipsForPreview
            previewGridProps={{ container: { spacing: 1, direction: "row" } }}
            previewText="Files to upload"
          />
        </DialogContent>
        <DialogActions sx={{ pr: 3 }}>
          <Button onClick={onClose} disabled={isUploadInProgress}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            disabled={filesToUpload.length === 0 || isUploadInProgress}
            onClick={() => handleUpload(filesToUpload)}>
            {isUploadInProgress ? "Uploading..." : "Upload"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
