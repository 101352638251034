import Typography from "@mui/material/Typography";
import React from "react";


export default function DocumentStatus(props) {
  const {document} = props;

  if (document.Status === "Processing") {
    return null;
  } else if (document.Status === "Base") {
    return null;
  } else if (document.Status === "Processed" & document.IsValidated) {
    return (
        <React.Fragment>
          <Typography>Verified</Typography>
        </React.Fragment>
    );
  } else if (document.Status === "Processed") {
    return (
        <React.Fragment>
          <Typography>Needs Review</Typography>
        </React.Fragment>
    );
  } else {
    return null;
  }
}