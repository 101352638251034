import styled from "@emotion/styled";
import { useState, useEffect } from "react";

// Pull this up to a file-level var so that it only needs to be loaded once per session
let ReportStyles = undefined;

export function useReportStyles() {
  const [hasStyles, setHasStyles] = useState(ReportStyles !== undefined);

  // This CSS is necessary for the charts to render properly, but it must only be applied
  // directly to the charts. If it is applied globally it causes huge conflicts.
  useEffect(() => {
    if (!hasStyles) {
      fetch("/styles/carbon-components.min.css").then((response) => {
        response.text().then((css) => {
          ReportStyles = styled.div`
            ${css}
          `;

          setHasStyles(true);
        });
      });
    }
  }, [hasStyles]);

  return ReportStyles;
}
