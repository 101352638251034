import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useAuth } from "./hooks/useAuth";

export default function LogIn(props) {
  const auth = useAuth();

  return (
    <Box sx={{ mt: 35, textAlign: "center" }}>
      <Typography sx={{ fontSize: 23, mb: 2 }} className={"fathom-gray"}>
        Welcome to Fathom
      </Typography>

      <Typography sx={{ fontSize: 14 }} className={"fathom-gray"}>
        Log in to start processing documents.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        disableElevation
        sx={{ mt: 5 }}
        style={{ minWidth: "200px", maxWidth: "200px" }}
        onClick={auth.login}>
        Log in
      </Button>
    </Box>
  );
}
