import axios from "axios";
import _ from "lodash";

export async function getEnvironmentalObservationsForRoomByDateRange(
  jwtToken,
  roomNumber,
  startDate,
  endDate
) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/em/observations`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    params: {
      RoomNumber: roomNumber,
      StartDate: startDate.toISODate(),
      EndDate: endDate.toISODate(),
    },
  }).then((response) => {
    let yValueField = response.data.ChartConfiguration.YAxis.Field;
    response.data.Data = _.filter(
      response.data.Data,
      (obs) => isChartWorthy(obs[yValueField])
    );
    return response.data;
  });
}

function isChartWorthy(dataValue) {
  return typeof dataValue === 'number' && !isNaN(dataValue);
}

export async function getRooms(jwtToken) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/em/rooms`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    return response?.data ?? [];
  });
}
