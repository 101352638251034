import React, { useState } from "react";
import classNames from "classnames";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useAuth } from "../../hooks/useAuth";
import { getTableExcelDownloadUrl } from "../../api/Documents";

export default function TableOverlay(props) {
  const { documentId, tableBlock } = props;

  const [isTableActive, setIsTableActive] = useState(false);
  const [pendingTimeout, setPendingTimeout] = useState(null);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

  const auth = useAuth();

  const onHoverIn = () => {
    if (pendingTimeout) {
      clearTimeout(pendingTimeout);
    }
  };

  const onHoverOut = () => {
    let timeout = setTimeout(() => setIsTableActive(false), 1000);
    setPendingTimeout(timeout);
  };

  const onDownloadExcel = () => {
    setIsDownloadInProgress(true);

    auth.getAccessJwtToken().then((token) => {
      getTableExcelDownloadUrl(token, documentId, tableBlock.Id).then(
        (downloadUrl) => {
          window.location = downloadUrl;
          setIsDownloadInProgress(false);
        }
      );
    });
  };

  const verticalHighlightPadding = 0.003;
  const horizontalHighlightPadding = 0.005;

  const top = tableBlock.Geometry.BoundingBox.Top - verticalHighlightPadding;
  const left =
    tableBlock.Geometry.BoundingBox.Left - horizontalHighlightPadding;
  const bottom =
    1 -
    tableBlock.Geometry.BoundingBox.Top -
    tableBlock.Geometry.BoundingBox.Height -
    verticalHighlightPadding;
  const right =
    1 -
    tableBlock.Geometry.BoundingBox.Left -
    tableBlock.Geometry.BoundingBox.Width -
    horizontalHighlightPadding;

  return (
    <Box
      sx={{
        "position": "absolute",
        "top": `${top * 100}%`,
        "left": `${left * 100}%`,
        "right": `${right * 100}%`,
        "bottom": `${bottom * 100}%`,
        "cursor": "pointer",
        "background": isTableActive
          ? "rgba(97, 122, 255, 0.10)"
          : "transparent",
        "border": "3px solid transparent",
        ":hover": {
          border: "3px solid",
          borderColor: "primary.main",
          borderRadius: 2,
        },
      }}
      className="table-hover"
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      onClick={() => setIsTableActive(true)}
      key={`table-block-${tableBlock.Id}`}>
      <Button
        onClick={onDownloadExcel}
        disabled={isDownloadInProgress}
        variant="contained"
        color="primary"
        disableElevation
        className={classNames("table-csv-download", { hidden: !isTableActive })}
        sx={{
          position: "absolute",
          top: "-4px",
          left: isDownloadInProgress ? "-118px" : "-155px",
        }}>
        {isDownloadInProgress ? "Downloading" : "Download to Excel"}
      </Button>
    </Box>
  );
}
