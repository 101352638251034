import React from "react";
import { CircularProgress } from "@mui/material";

import { useReportData } from "./useReportData";
import { useReportStyles } from "./useReportStyles";
import PrintFriendlyEMChart from "./PrintFriendlyEMChart";

import "./print.css";
import { DateTime } from "luxon";
import { useSearchParams } from "react-router-dom";

const formatTitleDate = (date) => date.toFormat("LLL dd, yyyy");
const formatCreateDate = (date) =>
  DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_FULL);

const LogoImage = ({ size = 24 }) => (
  <img src="/images/logo.svg" alt="Fathom Logo" width={size} height={size} />
);

const CustomerImage = ({ width = 200 }) => (
  <img src="/images/abeona.png" alt="Abeona Logo" width={width} /> // TODO pull this from chartConfiguration...
);

const Wordmark = ({ width = 80 }) => (
  <img src="/images/wordmark.svg" alt="Fathom Wordmark" width={width} />
);

export default function PrintReports() {
  const [params] = useSearchParams();
  const ReportStyles = useReportStyles();
  const reportData = useReportData(
    params.get("room"),
    params.get("start"),
    params.get("end")
  );

  const isPreparingCharts = ReportStyles === undefined || reportData.isLoading;

  const currentRoomName =
    reportData.currentRoom?.displayLabel ?? "Unknown Room";

  return (
    <article className="report">
      <header className="report-hed">
        <section className="report-details">
          <section className="fathom-branding">
            <LogoImage />
            <Wordmark />
          </section>
          <h2 className="report-title">Environmental Monitoring Report</h2>
        </section>
        <section className="client-branding">
          <CustomerImage />
        </section>
      </header>

      {isPreparingCharts ? (
        <section className="in-progress">
          <h5>Preparing charts...</h5>
          <CircularProgress size={36} sx={{ color: "#0071BC", opacity: 0.5 }} />
        </section>
      ) : (
        <>
          <section className="report-dek">
            <section className="report-strapline">
              <h4 className="sampling-location">
                {reportData.currentRoom.displayLabel}
              </h4>
              <h5 className="sampling-dates">
                {formatTitleDate(reportData.startDate)} &mdash;{" "}
                {formatTitleDate(reportData.endDate)}
              </h5>
            </section>
            <section className="report-creator">
              <h4 className="creator-email">
                {reportData.creator?.attributes?.email ?? "Abeona Team"}
              </h4>
              <h5 className="creator-print-date">
                {formatCreateDate(reportData.createdAt)}
              </h5>
            </section>
          </section>

          <section className="report-charts">
            {reportData.chartDefinitions.map((chart) => (
              <figure
                className="report-chart"
                key={`observation-chart-${chart.title}`}>
                <ReportStyles>
                  <PrintFriendlyEMChart
                    chartConfiguration={reportData.chartConfiguration}
                    title={chart.title}
                    roomName={currentRoomName}
                    observations={chart.observations}
                    thresholds={chart.thresholds}
                    height={360}
                    xMin={reportData.startDate}
                    xMax={reportData.endDate}
                    isLoading={false}
                  />
                </ReportStyles>
              </figure>
            ))}
          </section>
        </>
      )}
    </article>
  );
}
