import React from "react";
import { Auth } from "aws-amplify";

// Create a context that will hold the values that we are going to expose to our components.
// Don't worry about the `null` value. It's gonna be *instantly* overriden by the component below
export const AuthContext = React.createContext(null);

// Create a "controller" component that will calculate all the data that we need to give to our
// components bellow via the `UserContext.Provider` component. This is where the Amplify will be
// mapped to a different interface, the one that we are going to expose to the rest of the app.
export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    // Configure the keys needed for the Auth module. Essentially this is
    // like calling `Amplify.configure` but only for `Auth`.
    Auth.configure({
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,

      // Cognito Hosted UI configuration
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: [
          "email",
          "openid",
          "aws.cognito.signin.user.admin",
          "profile",
          process.env.REACT_APP_CUSTOM_SCOPE,
        ],
        redirectSignIn: `${process.env.REACT_APP_CLIENT_URL}/documents/`,
        redirectSignOut: `${process.env.REACT_APP_CLIENT_URL}/`,
        responseType: "code",
      },
    });

    // attempt to fetch the info of the user that was already logged in
    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(() => setUser(null));
  }, []);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  // can also add callbacks here
  const login = (from) =>
    Auth.federatedSignIn().then((cognitoUser) => {
      setUser(cognitoUser);
      return from;
    });

  // same thing here
  const logout = () =>
    Auth.signOut({ global: true }).then((data) => {
      setUser(null);
      return data;
    });

  const getAccessJwtToken = async function () {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
    let session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  };

  const getIdJwtToken = async function () {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
    let session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  };

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` value has changed. This is an optimisation that is mostly needed in cases
  // where the parent of the current component re-renders and thus the current component is forced
  // to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the
  // same value as long as the user data is the same. If you have multiple other "controller"
  // components or Providers above this component, then this will be a performance booster.
  const values = React.useMemo(
    () => ({ user, login, logout, getAccessJwtToken, getIdJwtToken }),
    [user]
  );

  // Finally, return the interface that we want to expose to our other components
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abtracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      "`useAuth` hook must be used within a `AuthProvider` component"
    );
  }
  return context;
};
