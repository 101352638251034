import { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { DateTime } from "luxon";
import { useAuth } from "../hooks/useAuth";

import {
  getEnvironmentalObservationsForRoomByDateRange,
  getRooms,
} from "../api/EnvironmentalObservations";

function getDisplayLabel(room) {
  const roomFields = Object.keys(room);

  const roomTypeString =
    roomFields.includes("Type") && room.Type.length > 0 ? `${room.Type} ` : ``;

  const roomNameString =
    roomFields.includes("Name") && room.Name.length > 0
      ? ` - ${room.Name}`
      : ``;

  return `${roomTypeString}${room.Number}${roomNameString}`;
}

function createChartFilterGroups(chartConfiguration, observations) {
  if (chartConfiguration === null || observations.length === 0) {
    return [];
  }

  let chartGroups = [];

  // Each field in the chartConfiguration.SeparateChartFields array represents fields for which different values
  // should be displayed on different charts. From the list of separate fields and the distinct values in the observations for
  // each such field, compute all the chart groupings that will be shown.

  _.each(chartConfiguration.SeparateChartFields, (fieldName) => {
    let distinctValuesForThisField = _.sortBy(
      _.uniq(observations.map((obs) => obs[fieldName]))
    );

    // For the first separate charts field, there are not yet any chart groups to add these field/value pairs to. Instead,
    // seed the chart groups with one chart group for each of the distinct values.
    if (chartGroups.length === 0) {
      _.each(distinctValuesForThisField, (fieldValue) => {
        chartGroups.push({ [fieldName]: fieldValue });
      });
    }

    // For every subsequent separate charts field, add each possible value to all of the currently computed chart groups.
    // This way every possible combination is enumerated.
    else {
      let nextChartGroups = [];
      _.each(chartGroups, (chartGroup) => {
        _.each(distinctValuesForThisField, (value) => {
          let nextChartGroup = _.clone(chartGroup);
          nextChartGroup[fieldName] = value;
          nextChartGroups.push(nextChartGroup);
        });
      });

      chartGroups = nextChartGroups;
    }
  });

  return chartGroups;
}

function getChartDefinition(filterGroup, observations, chartConfiguration) {
  const title = Object.entries(filterGroup)
    .map((filter) => `${filter[1]}`)
    .join(", ");

  // figure out which observations belong in each group
  let observationsInThisGroup = [...observations];

  for (let [filterField, filterValue] of Object.entries(filterGroup)) {
    observationsInThisGroup = _.filter(
      observationsInThisGroup,
      (observation) => observation[filterField] === filterValue
    );
  }

  // Determine which thresholds to show on this particular chart. Assume that the first point is representative of all
  // for the fields specified in the thresholds.
  let thresholds = [];

  if (observationsInThisGroup.length > 0) {
    const representativeObservation = observationsInThisGroup[0];

    chartConfiguration.YAxis.Thresholds.forEach((threshold) => {
      const fieldName = threshold["FieldName"];
      const fieldValue = threshold["FieldValue"];

      if (representativeObservation[fieldName] === fieldValue) {
        thresholds.push(threshold);
      }
    });
  }

  return {
    title,
    observations: observationsInThisGroup,
    thresholds,
  };
}

export function useReportData(
  initialRoomNumber = null,
  initialStartDate = "2021-11-15",
  initialEndDate = "2021-12-15"
) {
  const auth = useAuth();

  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [observations, setObservations] = useState([]);
  const [chartConfiguration, setChartConfiguration] = useState(null);

  const [roomNumber, setRoomNumber] = useState(initialRoomNumber ?? "");
  const [startDate, setStartDate] = useState(
    DateTime.fromISO(initialStartDate)
  );
  const [endDate, setEndDate] = useState(DateTime.fromISO(initialEndDate));

  // On initial page load.
  useEffect(() => {
    auth.getAccessJwtToken().then((token) => {
      getRooms(token).then((rooms) => {
        const roomList = Array.isArray(rooms) ? rooms : [];
        const orderedRoomList = _.orderBy(roomList, ["Type", "Number"]);
        const roomListWithLabels = orderedRoomList.map((room) => ({
          ...room,
          displayLabel: getDisplayLabel(room),
        }));

        setRooms(roomListWithLabels);

        if (roomList.length > 0) {
          const isInitialRoom = (room) => room.Number === initialRoomNumber;
          const noInitialRoomFound = roomList.find(isInitialRoom) === undefined;
          const defaultRoom = orderedRoomList[0];

          if (noInitialRoomFound) {
            setRoomNumber(defaultRoom.Number); // use the first room in the list
          }
        }
      });
    });
  }, [auth, initialRoomNumber]);

  // On change of any filter input.
  useEffect(() => {
    if (roomNumber === "") {
      return;
    }

    setIsLoading(true);

    auth.getAccessJwtToken().then((token) => {
      getEnvironmentalObservationsForRoomByDateRange(
        token,
        roomNumber,
        startDate,
        endDate
      ).then((data) => {
        const observations = Object.create({});

        Object.assign(observations, data);

        setObservations(observations.Data);
        setChartConfiguration(observations.ChartConfiguration);
        setIsLoading(false);
      });
    });
  }, [auth, roomNumber, startDate, endDate]);

  const chartFilterGroups = useMemo(
    () => createChartFilterGroups(chartConfiguration, observations),
    [chartConfiguration, observations]
  );

  const chartDefinitions = useMemo(
    () =>
      chartFilterGroups.map((filterGroup) =>
        getChartDefinition(filterGroup, observations, chartConfiguration)
      ),
    [chartFilterGroups, observations, chartConfiguration]
  );

  const currentRoom = rooms.find((r) => r.Number === roomNumber);

  const creator = auth.user;
  const createdAt = new Date();

  return {
    roomNumber,
    startDate,
    endDate,
    chartConfiguration,
    chartFilterGroups,
    chartDefinitions,
    rooms,
    currentRoom,
    observations,
    setRoomNumber,
    setStartDate,
    setEndDate,
    isLoading,
    creator,
    createdAt,
  };
}
