import axios from "axios";

export async function getDocuments(jwtToken) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/documents`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    return response.data;
  });
}

export async function getDocumentMetadata(jwtToken, documentId) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  })
    .then((response) => {
      let document = response.data;
      return document;
    })
    .catch((error) => {
      if (error.response.status === 403) {
        return { Status: "Forbidden" };
      }
    });
}

export async function getPageImage(jwtToken, documentId, pageNumber) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/pages/${pageNumber}/image`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    return response.data;
  });
}

export async function getPageTables(jwtToken, documentId, pageNumber) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/pages/${pageNumber}/tables`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    return response.data;
  });
}

export async function getPageEnvironmentalObservations(
  jwtToken,
  documentId,
  pageNumber
) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/pages/${pageNumber}/em-observations`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    return response.data;
  });
}

export async function getPageWords(jwtToken, documentId, pageNumber) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/pages/${pageNumber}/words`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    return response.data;
  });
}

export async function verifyDocument(jwtToken, documentId, isVerified) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/verify`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    data: {
      isVerified: isVerified,
    },
  }).then((response) => {
    return response.data;
  });
}

export async function getDocumentTableOfContents(jwtToken, documentId) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/toc`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    return response.data;
  });
}

export async function deleteDocument(jwtToken, documentId) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
}

export async function reprocessDocument(jwtToken, documentId) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/reprocess`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
}

export async function getDocumentDataCsvDownloadUrl(jwtToken, documentId) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/csv`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    let downloadUrl = response.data.url;
    return downloadUrl;
  });
}

export async function getTableExcelDownloadUrl(jwtToken, documentId, tableId) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/tables/${tableId}`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    let downloadUrl = response.data.url;
    return downloadUrl;
  });
}

export async function getBlock(jwtToken, documentId, blockId) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/blocks/${blockId}`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    return response.data;
  });
}

export async function verifyBlockText(jwtToken, documentId, blockId, text) {
  return axios({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}/blocks/${blockId}/verify/${text}`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    return response.data;
  });
}
