import { DropzoneArea } from "react-mui-dropzone";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    minHeight: "250px",
    padding: "0 125px",
    marginBottom: "20px",
    backgroundColor: "#F3F5FF",
    border: "2px dashed",
    borderColor: "#5f7afe",
    boxSizing: "border-box",
    cursor: "pointer",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  active: {
    animation: "$progress 2s linear infinite !important",
    // eslint-disable-next-line max-len
    // backgroundImage: `repeating-linear-gradient(-45deg, ${palette.background.paper}, ${palette.background.paper} 25px, ${palette.divider} 25px, ${palette.divider} 50px)`,
    backgroundSize: "150% 100%",
    border: "solid",
    borderColor: "#5f7afe",
  },
  invalid: {
    // eslint-disable-next-line max-len
    backgroundImage: `repeating-linear-gradient(-45deg, #E799A7, #E799A7 25px, #C20023 25px, #C20023 50px)`,
    borderColor: "#5f7afe",
  },
  textContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
  },
  text: {
    fontSize: "1.0rem",
    color: theme.palette.primary.main,
  },
  icon: {
    width: 41,
    height: 41,
    color: theme.palette.primary.main,
  },
}));

export default function StyledDropzoneArea(props) {
  const classes = useStyles();
  return (
    <DropzoneArea
      {...props}
      classes={{
        root: classes.root,
        textContainer: classes.textContainer,
        icon: classes.icon,
        text: classes.text,
      }}
    />
  );
}
