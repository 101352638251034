import { matchSorter } from "match-sorter";
import React, { useEffect, useCallback } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilCallback } from "recoil";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { DateTime } from "luxon";
import _ from "lodash";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  useFilters,
  useTable,
  useFlexLayout,
  usePagination,
  useSortBy,
} from "react-table";
import styled from "@emotion/styled";

import DocumentRowActions from "./DocumentRowActions";
import DocumentStatus from "./DocumentStatus";
import { useAuth } from "../hooks/useAuth";
import {
  getDocuments,
  getDocumentDataCsvDownloadUrl,
  reprocessDocument,
} from "../api/Documents";
import updateDocumentStatus from "./updateDocumentStatus";

import documentsState from "../recoil/documentsState";
import documentsStateFamily from "../recoil/documentsStateFamily";
import documentIdsState from "../recoil/documentIdsState";

export default function DocumentList({ filterText }) {
  const documents = useRecoilValue(documentsState);
  const setDocumentIds = useSetRecoilState(documentIdsState);
  const setDocument = useRecoilCallback(({ set }) => (document) => {
    set(documentsStateFamily(document.DocumentId), document);
  });

  const auth = useAuth();

  useEffect(
    () => {
      if (!auth.user) {
        return;
      }

      auth.getAccessJwtToken().then((token) => {
        getDocuments(token).then((documentsResponse) => {
          // Clear out the set of document ids so that updating the documents in the state does not force a recompute of
          // the documentsState on every document update. The set of document ids will be reset after each document has been
          // updated anyway.
          setDocumentIds([]);

          _.forEach(documentsResponse, (document) => {
            setDocument(document);
          });

          setDocumentIds(
            documentsResponse.map((document) => document.DocumentId)
          );
        });
      });
    },
    // setDocumentIds and setDocument are stable dependencies, but adding them causes infinite execution of this useEffect.
    [auth] // eslint-disable-line
  );

  const handleExcelDownload = useCallback(
    (documentId) => {
      ReactGA.event({ category: "Document", action: "Download data" });

      auth.getAccessJwtToken().then((token) => {
        getDocumentDataCsvDownloadUrl(token, documentId).then((downloadUrl) => {
          window.location = downloadUrl;
        });
      });
    },
    [auth]
  );

  const handleReprocessDocument = useCallback(
    (documentId) => {
      auth.getAccessJwtToken().then((token) => {
        reprocessDocument(token, documentId).then((response) => {
          updateDocumentStatus(token, documentId, setDocument);
        });
      });
    },
    // setDocument is a stable dependency, but adding it causes infinite execution of this useCallback.
    [auth] // eslint-disable-line
  );

  const Styles = styled.div`
    .table {
      border-spacing: 0;

      .thead {
        .tr {
          height: 42px;
        }
      }

      .tr {
        :last-child {
          .td {
            border-bottom: 0;
          }
        }
      }

      .tbody {
        .tr {
          background-color: white;

          :hover {
            background-color: #f4f9ff;
          }

          .td {
            padding: 0.25rem 0.75rem;
            display: flex;
            align-items: center;
          }

          .td .content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .th {
      background-color: #f6f7f9;
      padding: 0.75rem;
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 3px solid #e6eaf0;
      text-align: left;
      font-size: 14px;

      :last-child {
        margin-left: auto;
      }

      .th {
        background-color: #f6f7f9;
        color: dimgray;
      }

      .MuiTypography-root {
        margin-top: 3px;
      }
    }

    .pagination {
      text-align: right;
    }

    a {
      text-decoration: none;
    }
  `;

  const documentNameSort = React.useMemo(
    () => (documentA, documentB) => {
      return documentA.original.Filename > documentB.original.Filename ? 1 : -1;
    },
    []
  );

  const dateReceivedSort = React.useMemo(
    () => (documentA, documentB) => {
      return documentA.original.ReceivedTime > documentB.original.ReceivedTime
        ? 1
        : -1;
    },
    []
  );

  const data = React.useMemo(() => documents, [documents]);

  // @see https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/filtering
  // @see https://codesandbox.io/s/react-table-filter-outside-table-bor4f?file=/src/App.js
  // @see https://github.com/kentcdodds/match-sorter
  const filterTypes = React.useMemo(
    () => ({
      fileNameMatches: (rows, _, filterValue) => {
        // this uses the Property Callbacks feature of the match-sorter lib
        // to perform a fuzzy match against the filename value of all supplied rows
        const getFileNames = (row) => row.original.Filename;
        const matchingRows = matchSorter(rows, filterValue, {
          keys: [getFileNames],
        });

        return matchingRows;
      },
      fileNameStartsWith: (rows, _, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.original.Filename;

          if (rowValue === undefined || filterValue === undefined) return true;

          const filenameLC = String(rowValue).toLowerCase();
          const filterValueLC = String(filterValue).toLowerCase();

          return filenameLC.startsWith(filterValueLC);
        });
      },
    }),
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Document name",
        accessor: (document) => ( (document.Status === "Processing") ? document.Filename :
          <Link to={`/documents/${document.DocumentId}`}>
            {document.Filename}
          </Link>
        ),
        id: "Filename",
        sortType: documentNameSort,
        minWidth: 300,
        maxWidth: 600,
        filter: "fileNameMatches",
      },
      {
        Header: "Status",
        accessor: (document) =>
            (<DocumentStatus
                document={document}/>),
        minWidth: 100,
        maxWidth: 150,
      },
      {
        Header: "Date received",
        accessor: (document) =>
          DateTime.fromISO(document.ReceivedTime).toLocaleString(
            DateTime.DATETIME_MED
          ),
        sortType: dateReceivedSort,
        sortDescFirst: true,
        id: "dateReceived",
        minWidth: 150,
        maxWidth: 250,
      },
      {
        Header: "Uploaded by",
        accessor: (document) => document.UploadedByUser.EmailAddress,
        minWidth: 200,
        maxWidth: 300,
      },
      {
        Header: "Download data",
        accessor: (document) => (
          <DocumentRowActions
            document={document}
            handleExcelDownload={handleExcelDownload}
            handleReprocessDocument={handleReprocessDocument}
          />
        ),
        minWidth: 120,
        maxWidth: 120,
        id: "links",
        disableSortBy: true,
      },
    ],
    [
      handleExcelDownload,
      handleReprocessDocument,
      documentNameSort,
      dateReceivedSort,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    state: { pageIndex, pageSize },
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
        sortBy: [
          {
            id: "dateReceived",
            desc: true,
          },
        ],
      },
      disableSortRemove: true,
      filterTypes,
    },
    useFilters,
    useFlexLayout,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    // This will now use our custom filter for age
    setFilter("Filename", filterText);
  }, [setFilter, filterText]);

  const currentRowCount = rows.length;
  const thisPageFirstItemIndex = pageIndex * pageSize + 1;
  const thisPageLastItemIndex = Math.min(
    (pageIndex + 1) * pageSize,
    currentRowCount
  );

  const renderColumnHeader = (column) => {
    return (
      <div
        className="th fathom-gray"
        {...column.getHeaderProps(column.getSortByToggleProps())}>
        <Box sx={{ display: "inline", float: "left" }}>
          {column.render("Header")}
        </Box>
        {column.isSorted && (
          <Box sx={{ display: "inline", px: 0.5 }}>
            {column.isSortedDesc ? (
              <ArrowDropDownIcon sx={{ mt: -0.6 }} />
            ) : (
              <ArrowDropUpIcon sx={{ mt: -0.6 }} />
            )}
          </Box>
        )}
      </div>
    );
  };

  return (
    <Styles>
      <div className="table" {...getTableProps()}>
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <div className="tr" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => renderColumnHeader(column))}
            </div>
          ))}
        </div>
        <div className="tbody" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <div className="tr" {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <div className="td" {...cell.getCellProps()}>
                    <div className="content">{cell.render("Cell")}</div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      {currentRowCount > 0 && (
        <Box className="pagination" mt={1}>
          <Typography variant="caption" color="fathomGray.main">
            Showing {thisPageFirstItemIndex} - {thisPageLastItemIndex} of{" "}
            {currentRowCount}
            <IconButton
              onClick={previousPage}
              size="small"
              disabled={!canPreviousPage}
              sx={{ fontSize: 12 }}>
              <ArrowBackIosNewIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              onClick={nextPage}
              disabled={!canNextPage}
              size="small"
              sx={{ fontSize: 12 }}>
              <ArrowForwardIosIcon fontSize="inherit" />
            </IconButton>
          </Typography>
        </Box>
      )}
    </Styles>
  );
}