import React from "react";
import { useRecoilValue } from "recoil";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import ReplayIcon from "@mui/icons-material/Replay";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import isSuperuserState from "../recoil/isSuperuserState";

export default function DocumentRowActions(props) {
  const { document, handleExcelDownload, handleReprocessDocument } = props;
  const isSuperuser = useRecoilValue(isSuperuserState);

  const onReprocess = () => {
    handleReprocessDocument(document.DocumentId);
  };

  const getDownloadButton = () => {
    return (
      <Tooltip title="Download data in spreadsheet" placement="right-start">
        <IconButton
          onClick={() => handleExcelDownload(document.DocumentId)}
          color="primary"
          size="small"
          sx={{ ml: -1 }}>
          <DownloadIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    );
  };

  const getReprocessButton = () => {
    return (
      <Tooltip title="Reprocess document" placement="right-start">
        <IconButton
          onClick={onReprocess}
          color="primary"
          size="small"
          sx={{ ml: 1 }}>
          <ReplayIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    );
  };

  if (document.Status === "Processing" ) {
    return (
      <Typography sx={{ color: "dimgray" }}>
        <CircularProgress sx={{ ml: 2 }} size={14} />
      </Typography>
    );
  } else if (document.Status === "Processed" || document.Status === "Base") {
    return (
      <React.Fragment>
        {getDownloadButton()}
        {isSuperuser ? getReprocessButton() : null}
      </React.Fragment>
    );
  } else if (document.Status === "Failed") {
    return (
      <React.Fragment>
        <Typography sx={{ color: "dimgray" }}>
          Try Reprocessing Document
        </Typography>
        {isSuperuser ? getReprocessButton() : null}
      </React.Fragment>
    );
  } else {
    return null;
  }
}
