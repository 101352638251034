export const getMaxThresholdExceeded = (measuredValue, thresholds) => {
  const thresholdsExceeded = thresholds.filter((t) => measuredValue >= t.Value);
  const [maxThresholdExceeded] = thresholdsExceeded.sort(
    (a, b) => b.Value - a.Value
  );

  return maxThresholdExceeded;
};

export const getWarningLevel = (measuredValue, thresholds) => {
  const maxThresholdExceeded = getMaxThresholdExceeded(
    measuredValue,
    thresholds
  );
  const warningLevel = maxThresholdExceeded?.Label ?? "None";

  return warningLevel;
};

export const getWarningColor = (measuredValue, thresholds) => {
  const maxThresholdExceeded = getMaxThresholdExceeded(
    measuredValue,
    thresholds
  );
  const warningColor =
    maxThresholdExceeded?.Color?.toLowerCase() ?? "transparent";

  return warningColor;
};

// add the fields used by tooltip header
// (n.b. header is hidden if these fields aren't present)
export const getHeaderData = (observation, roomName, thresholds) => {
  const measuredValue = observation.Value ?? 0;
  const warningColor = getWarningColor(measuredValue, thresholds);

  return {
    roomName,
    warningColor,
  };
};

export const getVerificationData = (
  verifiedDate,
  verifiedByUserEmail,
  currentUserEmail
) => {
  if (verifiedDate === null || verifiedDate === undefined) return;

  const isVerifiedByCurrentUser = verifiedByUserEmail === currentUserEmail;
  const timeSinceVerification = verifiedDate.diffNow("seconds");
  const secondsElapsed = Math.abs(timeSinceVerification.seconds);

  const showRelativeTime = secondsElapsed > 5;

  const verifiedBy = isVerifiedByCurrentUser ? "you" : verifiedByUserEmail;
  const verifiedAt = showRelativeTime ? verifiedDate.toRelative() : "just now";

  const verificationDetails = `${verifiedAt} by ${verifiedBy}`;

  return {
    verificationDetails,
  };
};
