import { selectorFamily } from "recoil";
import _ from "lodash";

import tableOfContentsState from "./tableOfContentsState";

export default selectorFamily({
  key: "tableOfContentsPageSelector",
  get:
    (pageNumber) =>
    ({ get }) => {
      let tableOfContents = get(tableOfContentsState);
      return _.filter(
        tableOfContents,
        (entry) => entry["LinkedBlock"].Page === pageNumber
      );
    },
  dangerouslyAllowMutability: true,
});
