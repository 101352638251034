import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";

import FindInDocument from "./FindInDocument";
import DeleteDocumentButton from "./DeleteDocumentButton";
import DocumentStatusSelect from "./DocumentStatusSelect";
import DocumentModeSelect from "./DocumentModeSelect";

// more info on flexbox truncation techniques:
// https://css-tricks.com/flexbox-truncated-text/

export default function DocumentHeaderBar(props) {
  const { documentId, documentMetadata, isDocumentForbidden } = props;
  const shouldShowDocumentSpecificData =
    documentMetadata && !isDocumentForbidden;

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#5c6598",
        width: "100%",
        height: 64,
        boxShadow: 5,
        position: "fixed",
        zIndex: 50,
      }}>
      <Box sx={{ pl: 2, pt: 1.75 }}>
        <IconButton
          onClick={() => navigate("/documents")}
          size="small"
          color="appBarIcon"
          sx={{ fontSize: 24 }}>
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
      </Box>

      {shouldShowDocumentSpecificData ? (
        <React.Fragment>
          <Box
            sx={{
              px: 3,
              pt: 2.5,
              borderRight: "1px solid",
              borderColor: "secondary.main",
              minWidth: 0,
            }}>
            <Typography
              color="secondary"
              sx={{
                fontSize: 16,
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}>
              {documentMetadata.Filename}
            </Typography>
          </Box>

          <FindInDocument documentId={documentId} />

          <Box sx={{ marginLeft: "auto", marginRight: "auto", pt: 1.75 }}>
            <DocumentModeSelect />
          </Box>

          <Box
            sx={{
              marginLeft: "auto",
              px: 3,
              pt: 0.75,
              borderColor: "secondary.main",
              whiteSpace: "nowrap",
            }}>
            <DocumentStatusSelect
              documentId={documentId}
              isDocumentVerified={documentMetadata.IsValidated}
            />
          </Box>

          <Box
            sx={{
              borderLeft: "1px solid lightgray",
              borderColor: "secondary.main",
              whiteSpace: "nowrap",
            }}>
            <DeleteDocumentButton documentId={documentId} />
          </Box>
        </React.Fragment>
      ) : null}
    </Box>
  );
}
