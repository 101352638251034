import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { DateTime } from "luxon";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ToolTip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";

import { useAuth } from "../../hooks/useAuth";
import { verifyBlockText } from "../../api/Documents";

import currentUserState from "../../recoil/currentUserState";
import documentVerificationStatusState from "../../recoil/documentVerificationStatusState";
import ObservationTooltip from "../../tooltips/EnvironmentalObservationTooltip";

import { getVerificationData } from "../../tooltips/TooltipFields";

export default function EnvironmentalObservationOverlay(props) {
  const { observation, documentId, tooltipFields, updateObservation } = props;
  const auth = useAuth();

  let boundingBox = observation.BoundingBox;
  let top = boundingBox.Top;
  let left = boundingBox.Left + 1.1 * boundingBox.Width;
  let bottom = 1 - (boundingBox.Top + boundingBox.Height);
  let right = 1 - (boundingBox.Left + 2.2 * boundingBox.Width);

  const [isEditMode, setIsEditMode] = useState(false);
  const [currentEditedValue, setCurrentEditedValue] = useState(
    observation.Value
  );
  const currentUser = useRecoilValue(currentUserState);
  const isDocumentVerified =
    useRecoilValue(documentVerificationStatusState) === "verified";

  const handleSave = () => {
    observation.Value = currentEditedValue;
    observation.VerifiedDate = DateTime.now().toISO();
    observation.VerifiedByUserEmail = currentUser.EmailAddress;
    updateObservation(observation);
    setIsEditMode(false);

    auth.getAccessJwtToken().then((token) => {
      verifyBlockText(
        token,
        documentId,
        observation.CellBlockId,
        currentEditedValue
      ).catch((error) => {
        alert(`Failed to update block ${observation.CellBlockId}`);
        console.log(error);
      });
    });
  };

  const handleCancel = (event) => {
    if (
      event.relatedTarget &&
      event.relatedTarget.id === `save-button_${observation.CellBlockId}`
    ) {
      return;
    }

    setCurrentEditedValue(observation.Value);
    setIsEditMode(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSave();
    }
    if (event.key === "Escape") {
      handleCancel(event);
    }
  };

  const getDisplayBackgroundColor = () => {
    if (observation.VerifiedDate || isDocumentVerified) {
      return "rgba(0, 171, 102, 0.25)";
    }

    if (!observation.WasTextractExact) {
      return "rgba(255, 99, 71, 0.25)";
    }

    return "rgba(0, 0, 0, 0.0)";
  };

  const renderDisplayContent = () => {
    const backgroundColor = getDisplayBackgroundColor();

    return (
      <Box
        sx={{
          backgroundColor: { backgroundColor: backgroundColor },
          height: "100%",
          width: "100%",
          cursor: "text",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => setIsEditMode(true)}>
        <Typography
          variant="code"
          sx={{
            fontSize: { xs: "0.75rem", md: "1rem", lg: "1.5rem", xl: "2rem" },
            paddingRight: "40px",
          }}>
          {observation.Value}
        </Typography>
      </Box>
    );
  };

  const renderEditContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <TextField
          value={currentEditedValue}
          autoFocus
          onFocus={(event) => event.target.select()}
          onChange={(event) => setCurrentEditedValue(event.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleCancel}
          sx={{
            "& .MuiInputBase-adornedEnd": {
              paddingRight: 0.5,
            },
            "& .MuiInputBase-input": {
              padding: 0,
              textAlign: "center",
              fontSize: { xs: "0.75rem", md: "1rem", lg: "1.5rem", xl: "2rem" },
              fontFamily: "Courier New, source-code-pro, monospace",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  sx={{ "& .MuiIconButton-root": { padding: 0 } }}
                  size="small"
                  onClick={handleSave}
                  id={`save-button_${observation.CellBlockId}`}>
                  <CheckIcon color="primary" fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };

  const tooltipData = {
    ...observation,
    ...getVerificationData(
      observation.VerifiedDate
        ? DateTime.fromISO(observation.VerifiedDate)
        : null,
      observation.VerifiedByUserEmail,
      currentUser.EmailAddress
    ),
  };

  // see this ticket for explanation of style overrides approach for MUI ToolTip
  // https://github.com/mui/material-ui/issues/28679

  return (
    <ToolTip
      title={<ObservationTooltip fields={tooltipFields} item={tooltipData} />}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
            border: "1px solid #d3d3d3",
            borderRadius: "3px",
            padding: 0, // content has padding
          },
        },
      }}>
      <Box
        {...props}
        sx={{
          position: "absolute",
          top: `${top * 100}%`,
          left: `${left * 100}%`,
          right: `${right * 100}%`,
          bottom: `${bottom * 100}%`,
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          border: "1px dashed gray",
          cursor: "text",
        }}>
        {isEditMode ? renderEditContent() : renderDisplayContent()}
      </Box>
    </ToolTip>
  );
}
