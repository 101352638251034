import React from "react";
import ReactDOMServer from "react-dom/server";

import Tooltip from "../tooltips/EnvironmentalObservationTooltip";
import { getHeaderData } from "../tooltips/TooltipFields";

export const openLinkedDataPoint = (event) => {
  const observation = event.detail?.datum;
  const sourceUrl = observation?.Link;

  if (sourceUrl === undefined) return;

  window.open(sourceUrl, "_blank");
};

// compose tooltip data including example fields
const composeTooltipData = (observation, roomName, thresholds) => ({
  ...observation,
  ...getHeaderData(observation, roomName, thresholds),
});

// use example field definitions
// to illustrate how the tooltip might support micro-organism data
// n.b. fields will ultimately come from chartConfiguration.TooltipFields or similar;
const renderTooltipMarkup = (data, tooltipFields) =>
  ReactDOMServer.renderToStaticMarkup(
    <Tooltip
      item={data}
      fields={tooltipFields}
      disableLinks={true} // Carbon Charts doesn't support tooltip interactions
    />
  );

export function useChartOptions({
  title,
  roomName,
  chartConfiguration,
  thresholds,
  isLoading,
  height,
  xMin,
  xMax,
  printFriendly = false,
}) {
  // most options can be memo-ized as they aren't going to change that often
  const options = React.useMemo(
    () => ({
      animations: !printFriendly,
      title: title,
      axes: {
        bottom: {
          title: chartConfiguration.XAxis.Label,
          mapsTo: chartConfiguration.XAxis.Field,
          scaleType: "time",
          domain: [xMin, xMax],
        },
        left: {
          title: chartConfiguration.YAxis.Label,
          mapsTo: chartConfiguration.YAxis.Field,
          scaleType: "linear",
          thresholds: thresholds.map((threshold) => {
            return {
              value: threshold.Value,
              label: `${threshold.Label} threshold`,
              fillColor: printFriendly ? "#666" : threshold.Color,
            };
          }),
        },
      },
      color: {
        scale: {
          "Verified": printFriendly ? "#2C9D45" : "#37807a",
          "Needs Review": printFriendly ? "#0071BC" : "#617aff",
        },
      },
      points: {
        radius: 6,
        fillOpacity: 0.2,
        filled: true,
        enabled: true,
      },
      legend: {
        enabled: !printFriendly,
      },
      legendClickable: !printFriendly,
      toolbar: printFriendly
        ? undefined
        : {
            numberOfIcons: 2,
            controls: [
              { type: "Make fullscreen" },
              { type: "Export as PNG" },
              { type: "Export as JPG" },
            ],
          },
      height: `${height}px`,
    }),
    [title, chartConfiguration, thresholds, xMin, xMax, height, printFriendly]
  );

  const tooltipOptions = React.useMemo(
    () => ({
      tooltip: {
        showTotal: false,
        customHTML: (observations) => {
          if (observations.length !== 1) return null;

          const item = observations[0];
          const data = composeTooltipData(item, roomName, thresholds);
          const html = renderTooltipMarkup(
            data,
            chartConfiguration.TooltipFields
          );

          return html;
        },
      },
    }),
    [roomName, thresholds, chartConfiguration]
  );

  return printFriendly
    ? options
    : {
        ...options,
        ...tooltipOptions,
        data: {
          loading: isLoading,
        },
      };
}

export function useCustomChartEvents(chartRef) {
  const overrides = React.useMemo(
    () => ({
      "scatter-click": openLinkedDataPoint,
      // "scatter-mouseover": (event) => console.log({ type: 'mouseover', event }),
      // "scatter-mouseout": (event) => console.log({ type: 'mouseout', event }),
      // "scatter-mousemove": (event) => console.log({ type: 'mousemove', event })
    }),
    []
  );
  const hasBoundOverrides = React.useRef(false);

  React.useEffect(() => {
    const chartEvents = chartRef.current?.chart?.services?.events;
    const readyToBind = chartEvents !== undefined;
    const needsBinding = !hasBoundOverrides.current;

    // once chart ref is available, bind DOM listeners once only
    if (readyToBind && needsBinding) {
      Object.entries(overrides).forEach(([eventName, handler]) =>
        chartEvents.addEventListener(eventName, handler)
      );

      hasBoundOverrides.current = true;
    }
  }, [chartRef, overrides]);

  return function cleanup() {
    const chartEvents = chartRef.current?.chart?.services?.events;
    const hasChartEvents = chartEvents !== undefined;
    const shouldCleanup = hasChartEvents && hasBoundOverrides.current;

    if (shouldCleanup) {
      Object.entries(overrides).forEach(([eventName, handler]) =>
        chartEvents.removeEventListener(eventName, handler)
      );
    }
  };
}

// Problem: threshold line interactions block data point interactions
// @see https://fathomdata.atlassian.net/browse/QUAL-95

// Problem: Carbon charts do not allow layer order to be configured
// @see https://github.com/carbon-design-system/carbon-charts/issues/731

// Workaround:
// Once chart element is available, use sneaky DOM voodoo to swap layers
// (but only if we have evidence that the scatter plot rendered successfully!)
export function useLayerSwitching(chartRef) {
  const hasSwitchedLayers = React.useRef(false);

  React.useEffect(() => {
    const chartElement = chartRef.current?.chartRef;
    const hasChartElement = chartElement !== undefined;

    if (hasChartElement && !hasSwitchedLayers.current) {
      const chartEl = chartRef.current.chartRef;

      const [wrapper] = chartEl.getElementsByClassName("layout-svg-wrapper");
      const [scatter] = wrapper?.getElementsByClassName("bx--cc--scatter");
      const [threshold] = wrapper?.getElementsByClassName("bx--cc--threshold");

      const circles = scatter?.getElementsByTagName("circle") ?? [];
      const hasChartRendered = circles.length > 0 && threshold !== undefined;

      if (hasChartRendered) {
        wrapper.insertBefore(threshold, scatter);
        hasSwitchedLayers.current = true;
      }
    }
  }, [chartRef]);
}

// Problem:
// Threshold lines don't have labels for print purposes
//
// Workaround:
// Once chart element is available, use sneaky DOM voodoo to add labels
// based on location of threshold-group items
export function useThresholdLabels(chartRef, thresholdItems) {
  const [hasWaitedForLayout, setHasWaitedForLayout] = React.useState(false);
  const isWaitingForLayout = React.useRef(false);
  const hasAddedLabels = React.useRef(false);

  React.useEffect(() => {
    const chartElement = chartRef.current?.chartRef;
    const hasChartElement = chartElement !== undefined;

    if (hasChartElement && !hasWaitedForLayout && !isWaitingForLayout.current) {
      setTimeout(() => {
        setHasWaitedForLayout(true);
      }, 250);

      isWaitingForLayout.current = true;
    }

    if (hasChartElement && hasWaitedForLayout && !hasAddedLabels.current) {
      const SVG_NS = "http://www.w3.org/2000/svg";
      const chartEl = chartRef.current.chartRef;

      const [wrapper] = chartEl.getElementsByClassName("layout-svg-wrapper");
      const [thresholds] = wrapper?.getElementsByClassName("bx--cc--threshold");
      const [xAxisThreshold] =
        thresholds?.getElementsByClassName("axis-thresholds");
      const thresholdGroups =
        xAxisThreshold?.getElementsByClassName("threshold-group") ?? [];

      const thresholdList = Array.from(thresholdItems);

      for (const group of thresholdGroups) {
        const [line] = group.getElementsByClassName("threshold-line");
        const xPos = line?.getAttribute("x2");
        const yPos = line?.getAttribute("y1");
        const label = document.createElementNS(SVG_NS, "text");
        const data = thresholdList.shift();

        if (xPos === undefined || yPos === undefined || data === undefined)
          return;

        label.classList.add("threshold-label");

        label.setAttribute("y", yPos);
        label.setAttribute("x", xPos);
        label.setAttribute("dx", "-4");
        label.setAttribute("dy", "-4");

        label.insertAdjacentText("afterbegin", data.Label);

        group.append(label);
      }

      hasAddedLabels.current = true;
    }
  }, [chartRef, thresholdItems, hasWaitedForLayout]);
}
