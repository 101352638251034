import { selector } from "recoil";

import currentUserState from "./currentUserState";

export default selector({
  key: "isSuperuser",
  get: ({ get }) => {
    const currentUser = get(currentUserState);
    return currentUser && currentUser.IsSuperuser;
  },
});
