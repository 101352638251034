import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import _ from "lodash";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import documentLayersState from "../recoil/documentLayersState";
import tableOfContentsState from "../recoil/tableOfContentsState";
import isSuperuserState from "../recoil/isSuperuserState";

export default function DocumentNavPane(props) {
  const [layers, setLayers] = useRecoilState(documentLayersState);
  const tableOfContents = useRecoilValue(tableOfContentsState);
  const isSuperuser = useRecoilValue(isSuperuserState);

  const toggleHandwritingLayer = () => {
    let newLayers = _.clone(layers);
    newLayers.handwritingOverlay = !layers.handwritingOverlay;
    setLayers(newLayers);
  };

  const toggleEnvironmentalMonitoringLayer = () => {
    let newLayers = _.clone(layers);
    newLayers.environmentalMonitoringObservations =
      !layers.environmentalMonitoringObservations;
    setLayers(newLayers);
  };

  // only render to DOM if current user has superuser access

  return isSuperuser ? (
    <Drawer
      sx={{
        "display": "block",
        "width": 300,
        "flexShrink": 0,
        "zIndex": 30,
        "boxShadow": 2,
        "& .MuiDrawer-paper": {
          width: 300,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left">
      <Box sx={{ mt: 18 }}>
        {tableOfContents.length > 0 && (
          <Box sx={{ textAlign: "left", pl: 2, pb: 3 }}>
            <Typography variant="h6" color="fathomGray.main">
              Table of contents
            </Typography>
            {tableOfContents.map((entry) => {
              return (
                <Link
                  onClick={() =>
                    entry.LinkedBlock.scrollRef.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }
                  sx={{ pl: 2, py: 0.25, display: "block" }}
                  color="primary"
                  underline="none"
                  key={`table-of-contents-link-${entry.LinkedBlock.Id}`}>
                  {entry.SectionName}
                </Link>
              );
            })}
          </Box>
        )}

        <Typography
          variant="h6"
          color="fathomGray.main"
          sx={{ textAlign: "left", pl: 2 }}>
          Visualize
        </Typography>

        <FormGroup sx={{ pl: 4 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={layers.handwritingOverlay}
                onClick={toggleHandwritingLayer}
              />
            }
            label="Handwriting"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={layers.environmentalMonitoringObservations}
                onClick={toggleEnvironmentalMonitoringLayer}
              />
            }
            label="EM observations"
          />
        </FormGroup>
      </Box>
    </Drawer>
  ) : null;
}
