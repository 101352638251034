import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function TextOverlay(props) {
  const { boundingBox, text } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        top: boundingBox.top,
        left: boundingBox.left,
        right: boundingBox.right,
        bottom: boundingBox.bottom,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      }}>
      <Typography
        variant="code"
        color="primary"
        sx={{ fontWeight: "bold", fontSize: 20 }}>
        {text}
      </Typography>
    </Box>
  );
}
