import React, { useEffect } from "react";

import Box from "@mui/material/Box";

import useBoundingBoxes from "../../hooks/useBoundingBoxes";

export default function HighlightedBlock(props) {
  const { block } = props;

  const getPercentageBasedBoundingBox = useBoundingBoxes();
  const boundingBox = getPercentageBasedBoundingBox(block);

  useEffect(() => {
    block.scrollRef.current.scrollIntoView({ behavior: "smooth" });
  });

  return (
    <Box
      sx={{
        position: "absolute",
        top: boundingBox.top,
        left: boundingBox.left,
        right: boundingBox.right,
        bottom: boundingBox.bottom,
        scrollMarginTop: 450,
        backgroundColor: "rgba(255, 233, 101, 0.55)",
        border: "1px dashed lightgray",
      }}
      ref={block.scrollRef}></Box>
  );
}
