import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import DocumentPage from "./DocumentPage";
import DocumentHeaderBar from "./DocumentHeaderBar";
import DocumentNavPane from "./DocumentNavPane";
import {
  getDocumentMetadata,
  getDocumentTableOfContents,
  getBlock,
} from "../api/Documents";
import { useAuth } from "../hooks/useAuth";

import documentVerificationStatusState from "../recoil/documentVerificationStatusState";
import searchResultsState from "../recoil/findInDocumentSearchResultsState";
import tableOfContentsState from "../recoil/tableOfContentsState";

export default function Document(props) {
  const params = useParams();
  const documentId = params.documentId;
  const auth = useAuth();

  const [documentMetadata, setDocumentMetadata] = useState(null);
  const [isDocumentForbidden, setIsDocumentForbidden] = useState(false);
  const [highlightedBlock, setHighlightedBlock] = useState(null);
  const setDocumentVerificationStatus = useSetRecoilState(
    documentVerificationStatusState
  );
  const searchResults = useRecoilValue(searchResultsState);
  const setTableOfContents = useSetRecoilState(tableOfContentsState);

  const [searchParams] = useSearchParams();
  const highlightedBlockId = searchParams.get("blockId");

  useEffect(() => {
    auth.getAccessJwtToken().then((token) => {
      getDocumentMetadata(token, documentId).then((documentMetadata) => {
        if (documentMetadata.Status === "Forbidden") {
          setIsDocumentForbidden(true);
        } else {
          setDocumentMetadata(documentMetadata);
          setDocumentVerificationStatus(
            documentMetadata.IsValidated ? "verified" : "unverified"
          );
        }
      });

      if (highlightedBlockId) {
        getBlock(token, documentId, highlightedBlockId).then(
          (highlightedBlock) => {
            highlightedBlock["scrollRef"] = React.createRef();
            setHighlightedBlock(highlightedBlock);
          }
        );
      }

      getDocumentTableOfContents(token, documentId).then((tableOfContents) => {
        _.forEach(tableOfContents, (entry) => {
          entry["LinkedBlock"]["scrollRef"] = React.createRef();
        });

        setTableOfContents(tableOfContents);
      });
    });
  }, [
    auth,
    documentId,
    highlightedBlockId,
    setDocumentVerificationStatus,
    setTableOfContents,
  ]);

  // Clear out the table of contents Recoil state when this document is closed.
  useEffect(() => {
    return () => {
      setTableOfContents([]);
    };
  }, [setTableOfContents]);

  let pageNumbers = [];
  if (documentMetadata && !isDocumentForbidden) {
    pageNumbers = [...Array(documentMetadata.PageCount).keys()].map(
      (index) => index + 1
    );
  }

  let searchResultsByPageNumber = _.groupBy(searchResults, "Page");

  return (
    <Box>
      <DocumentHeaderBar
        documentId={documentId}
        documentMetadata={documentMetadata}
        isDocumentForbidden={isDocumentForbidden}
      />

      <Box sx={{ display: "flex" }}>
        {documentMetadata && !isDocumentForbidden && <DocumentNavPane />}

        <Box sx={{ pt: 15, mx: "5%", flexGrow: 1 }}>
          {pageNumbers.map((pageNumber) => {
            return (
              <DocumentPage
                documentId={documentId}
                pageNumber={pageNumber}
                highlightedBlock={
                  highlightedBlock && highlightedBlock.Page === pageNumber
                    ? highlightedBlock
                    : null
                }
                searchResults={
                  pageNumber in searchResultsByPageNumber
                    ? searchResultsByPageNumber[pageNumber]
                    : []
                }
                key={`document-page-${pageNumber}`}
              />
            );
          })}

          {isDocumentForbidden && (
            <Typography align="center" sx={{ pt: 15 }}>
              The specified document does not exist or you do not have
              permission to view it.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
