import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import ReactGA from "react-ga4";
import _ from "lodash";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useAuth } from "../hooks/useAuth";
import { getSearchResultsForQuery } from "../api/Search";
import searchResultsState from "../recoil/findInDocumentSearchResultsState";

export default function FindInDocument(props) {
  const { documentId } = props;
  const [findText, setFindText] = useState("");
  const [lastSearchTerm, setLastSearchTerm] = useState(null);
  const [searchResultFocusIndex, setSearchResultFocusIndex] = useState(0);
  const [searchResults, setSearchResults] = useRecoilState(searchResultsState);

  const auth = useAuth();

  const onTextChange = (event) => {
    setFindText(event.target.value);
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      if (findText !== lastSearchTerm) {
        onFind();
      } else {
        incrementSearchResultFocusIndex();
      }

      event.preventDefault();
    }
  };

  function onFind() {
    ReactGA.event({
      category: "Document",
      action: "Find in page",
      label: findText,
    });

    auth.getAccessJwtToken().then((token) => {
      getSearchResultsForQuery(token, documentId, findText).then((results) => {
        _.forEach(results, (result) => {
          result["scrollRef"] = React.createRef();
        });

        setSearchResults(results);
        setSearchResultFocusIndex(0);
        setLastSearchTerm(findText);
      });
    });
  }

  function clearSearch() {
    setSearchResults([]);
    setFindText("");
    setLastSearchTerm(null);
  }

  function incrementSearchResultFocusIndex() {
    let newFocusIndex = searchResultFocusIndex + 1;
    if (newFocusIndex >= searchResults.length) {
      newFocusIndex = 0;
    }
    setSearchResultFocusIndex(newFocusIndex);
  }

  function decrementSearchResultFocusIndex() {
    let newFocusIndex = searchResultFocusIndex - 1;
    if (newFocusIndex < 0) {
      newFocusIndex = searchResults.length - 1;
    }
    setSearchResultFocusIndex(newFocusIndex);
  }

  useEffect(() => {
    if (searchResults.length > 0) {
      searchResults[searchResultFocusIndex].scrollRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [searchResults, searchResultFocusIndex]);

  useEffect(() => {
    // Clear out the Recoil state of search results when the current document is closed.
    return () => {
      setSearchResults([]);
      setSearchResultFocusIndex(0);
    };
  }, [setSearchResults]);

  let isSearchActive = lastSearchTerm !== null;

  return (
    <React.Fragment>
      <Box sx={{ px: 1.5, width: 250 }}>
        <Input
          className="find-in-document"
          fullWidth
          disableUnderline
          placeholder="Search for keywords..."
          onKeyPress={onKeyPress}
          onChange={onTextChange}
          value={findText}
          sx={{
            "fontSize": 16,
            "borderRadius": 1,
            "border": "1px solid transparent",
            "px": 2,
            "py": 1,
            "my": 1,
            "color": "secondary.main",
            ":hover": { border: "1px solid", borderColor: "white" },
            ":focus-within": { border: "1px solid", borderColor: "white" },
          }}
          startAdornment={
            <InputAdornment position="start">
              <IconButton edge="start" onClick={onFind} color="appBarIcon">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            isSearchActive && (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={clearSearch} color="appBarIcon">
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </Box>

      {isSearchActive && (
        <Box
          sx={{
            pt: 2.75,
            color: "appBarIcon.main",
            whiteSpace: "nowrap",
          }}>
          <Typography>
            {Math.min(searchResultFocusIndex + 1, searchResults.length)} of{" "}
            {searchResults.length}
          </Typography>
        </Box>
      )}

      {isSearchActive && (
        <Box sx={{ pt: 1.25, pl: 1.5, whiteSpace: "nowrap" }}>
          <IconButton
            onClick={decrementSearchResultFocusIndex}
            color="appBarIcon">
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton
            onClick={incrementSearchResultFocusIndex}
            color="appBarIcon">
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
      )}
    </React.Fragment>
  );
}
