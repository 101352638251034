import axios from "axios";

export async function getSearchResultsForQuery(jwtToken, documentId, query) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/documents/${documentId}/search/${encodeURIComponent(query)}`,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((response) => {
    let searchResults = response.data;
    return searchResults.map((result) =>
      getRepresentativeSearchResultForBlocks(result)
    );
  });
}

function getRepresentativeSearchResultForBlocks(searchResultBlocks) {
  let top = Math.min(
    ...searchResultBlocks.map((b) => b.Geometry.BoundingBox.Top)
  );
  let bottom = Math.max(
    ...searchResultBlocks.map(
      (b) => b.Geometry.BoundingBox.Top + b.Geometry.BoundingBox.Height
    )
  );

  let left = Math.min(
    ...searchResultBlocks.map((b) => b.Geometry.BoundingBox.Left)
  );
  let right = Math.max(
    ...searchResultBlocks.map(
      (b) => b.Geometry.BoundingBox.Left + b.Geometry.BoundingBox.Width
    )
  );

  return {
    Geometry: {
      BoundingBox: {
        Top: Math.min(
          ...searchResultBlocks.map((b) => b.Geometry.BoundingBox.Top)
        ),
        Left: Math.min(
          ...searchResultBlocks.map((b) => b.Geometry.BoundingBox.Left)
        ),
        Height: bottom - top,
        Width: right - left,
      },
    },
    Page: searchResultBlocks[0].Page,
    Id: searchResultBlocks[0].Id,
  };
}
