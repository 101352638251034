import { selector } from "recoil";
import _ from "lodash";

import documentIdsState from "./documentIdsState";
import documentsStateFamily from "./documentsStateFamily";

export default selector({
  key: "documents",
  get: ({ get }) => {
    const documentIds = get(documentIdsState);

    let documents = _.map(documentIds, (documentId) =>
      get(documentsStateFamily(documentId))
    );
    documents = _.orderBy(documents, ["ReceivedTime"], ["desc"]);

    return documents;
  },
});
