import axios from "axios";

export default async function getUser(jwtIdToken) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/users/current`,
    headers: {
      Authorization: `Bearer ${jwtIdToken}`,
    },
  }).then((response) => {
    return response.data;
  });
}
