import { getDocumentMetadata } from "../api/Documents";

export default function updateDocumentStatus(
  jwtToken,
  documentId,
  setDocument,
  nextBackoffMilliseconds = 500
) {
  getDocumentMetadata(jwtToken, documentId).then((document) => {
    // Only keep checking the document if it is still processing. If the document was forbidden, just try
    // again -- because the server may just not be aware of the document yet.
    if (
      !document ||
      document.Status === "Processing" ||
      document.Status === "Forbidden" || document.Status === "Base"
    ) {
      setTimeout(() => {
        // Back off of the endpoint exponentially to avoid spamming the endpoint for large documents.
        updateDocumentStatus(
          jwtToken,
          documentId,
          setDocument,
          Math.min(nextBackoffMilliseconds * 1.25, 5000)
        );
      }, nextBackoffMilliseconds);
    }

    // Otherwise, this document status we have received is the final status.
    else {
      setDocument(document);
    }
  });
}
