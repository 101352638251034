import React from "react";
import _ from "lodash";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterLuxon";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";

import EnvironmentalMonitoringChart from "./EnvironmentalMonitoringChart";
import ChartPlaceholders from "./ChartPlaceholders";
import LoadingMessage from "./LoadingMessage";
import { IconButton } from "@mui/material";
import { Print } from "@mui/icons-material";
import { useReportData } from "./useReportData";
import { useReportStyles } from "./useReportStyles";
import { useNavigate } from "react-router-dom";

// TODO implement a proper feature flag check!!
function hasAccess(user) {
  const email = String(user?.attributes?.email ?? "");
  const isFathom = email.endsWith("fathom.one");
  const isAbeona = email.endsWith("abeonatherapeutics.com");

  return isFathom || isAbeona;
}

export default function Reports() {
  const ReportStyles = useReportStyles();
  const reportData = useReportData();
  const navigate = useNavigate();

  const { startDate, endDate, roomNumber } = reportData;

  const openPrintFriendlyPage = React.useCallback(() => {
    const params = new URLSearchParams();

    params.set("start", startDate.toISODate());
    params.set("end", endDate.toISODate());
    params.set("room", roomNumber);

    navigate(`/print/reports?${params.toString()}`);
  }, [navigate, startDate, endDate, roomNumber]);

  const canPrint = !reportData.isLoading && hasAccess(reportData.creator);
  const isPreparingCharts = ReportStyles === undefined || reportData.isLoading;

  const currentRoomName =
    reportData.currentRoom?.displayLabel ?? "Unknown Room";

  console.log({ reportData });

  return (
    <Box sx={{ px: { xs: 0, md: "10%" }, pt: 7 }}>
      <Box
        sx={{
          display: "flex",
          mb: 2,
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}>
        <Typography variant={"h4"} color="fathomGray.main">
          Environmental monitoring reports
        </Typography>
        {canPrint && (
          <IconButton onClick={openPrintFriendlyPage}>
            <Print />
          </IconButton>
        )}
      </Box>

      {isPreparingCharts ? (
        <LoadingMessage text="Preparing charts..." />
      ) : (
        <Box sx={{ textAlign: "left", mb: 2 }}>
          <FormControl sx={{ pr: 5 }}>
            <Typography>Room:</Typography>
            <Select
              value={reportData.roomNumber}
              onChange={(event) => reportData.setRoomNumber(event.target.value)}
              autoWidth
              sx={{ minWidth: 100, backgroundColor: "white" }}
              inputProps={{ "aria-label": "Without label" }}>
              {_.map(reportData.rooms, (room) => {
                return (
                  <MenuItem
                    value={room.Number}
                    key={`room-menu-item-${room.Number}`}>
                    {room.displayLabel}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl sx={{ pr: 5 }}>
            <Typography>Start date:</Typography>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                value={reportData.startDate}
                onChange={(date) => reportData.setStartDate(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ backgroundColor: "white", width: 150 }}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl sx={{ pr: 5 }}>
            <Typography>End date:</Typography>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                value={reportData.endDate}
                onChange={(date) => reportData.setEndDate(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ backgroundColor: "white", width: 150 }}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>
      )}

      <Box sx={{ textAlign: "left", pb: 10 }}>
        {isPreparingCharts ? (
          <ChartPlaceholders count={4} />
        ) : (
          <Grid container columnSpacing={15} rowSpacing={7}>
            {reportData.chartDefinitions.map((chart) => (
              <Grid
                item
                xs={12}
                md={6}
                key={`observation-chart-${chart.title}`}>
                <ReportStyles>
                  <EnvironmentalMonitoringChart
                    chartConfiguration={reportData.chartConfiguration}
                    title={chart.title}
                    roomName={currentRoomName}
                    observations={chart.observations}
                    thresholds={chart.thresholds}
                    width={1000}
                    height={500}
                    xMin={reportData.startDate}
                    xMax={reportData.endDate}
                    isLoading={false}
                  />
                </ReportStyles>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
}
